
import Vue from "vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import {MenuItem} from "@/nav-lib";

export default Vue.extend({
	name: "nav-menu-leaf",
	mixins: [SelectedUtilityMixin],
	props:{
		item: Object as () => MenuItem,
	},
	computed: {
		to(){
			if (this.item.name) {
				return {
					name: this.item.name,
					params: {
						...(this.item.reuseParams ? this.$route.params : {}),
						utility: (this as any).selectedUtility,
						...(this.item.params ?? {})
					},
				}
			}
			else {
				return this.item.href;
			}
		},
	}
});
