export default {
	inject: ['reactive'],
	computed: {
		selectedUtility: {
			get(){
				return this.reactive.utility;
			},
			set(newValue){
				this.reactive.utility = newValue;
			},
		},
		selectedUtilityData(){
			return this.reactive.utilityOptions.find(
				item => this.selectedUtility && item.value == this.selectedUtility
			) || null;
		},
	},
}